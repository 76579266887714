<template>
  <div>
    <FileUpload
      @removeFile="onRemoveFile"
      @selectFile="selectFile"
      @onUploadSuccess="updateFiles"
      :uploadingUrl="getAddFileUrl"
      :fileTypes="[
        '.pdf',
        '.jpeg',
        '.jpg',
        '.png',
        '.doc',
        '.docx',
        '.xlsx',
        '.ppt'
      ]"
      :files="files"
      :id="complianceId"
      :isLoading="isLoading"
      :showValidationErrors="showValidationErrors"
      customActions
      isMultiple
      disablePreview
    />
  </div>
</template>

<script>
import FileUpload from "@/components/common/FileUpload";
import APIService from "@/services/APIService";
import { mapActions } from "vuex";
import { REMOVE_FILE } from "@/store/modules/data/actions";
import { filter } from "lodash";

export default {
  props: {
    compliance: Object,
    showValidationErrors: Boolean
  },
  components: {
    FileUpload
  },
  data() {
    return {
      isLoading: false,
      files: this.compliance.files
    };
  },
  computed: {
    getAddFileUrl() {
      const host = APIService.host;
      return `${host}/compliance-checks/${this.complianceId}/file`;
    },
    complianceId() {
      return this.compliance.id;
    }
  },
  methods: {
    ...mapActions("data", {
      removeFile: REMOVE_FILE
    }),
    selectFile({ uppyInstance, file }) {
      uppyInstance.setFileState(file.id, {
        xhrUpload: { endpoint: this.getAddFileUrl }
      });
    },
    async onRemoveFile({ file: fileToBeRemoved }) {
      this.files = filter(this.files, file => file.id !== fileToBeRemoved.id);
      this.isLoading = true;
      if (fileToBeRemoved.url) {
        await this.removeFile(fileToBeRemoved.url);
      }
      this.isLoading = false;
    },
    updateFiles(files) {
      this.files = files;
    }
  }
};
</script>
